<!-- @Author: Yu_Bo 2023-09-05 18:28:44-->
<template>
    <div class="screen_vue">
        <!-- 标题 -->
        <div class="vue_title">公屏管理</div>
        <!--  -->
        <div class="screen_main">
            <div class="main_condition">
                <div class="cond_left">
                    <el-button type="primary" class="btnBgColor_blue_empty" @click="addScreen" size="small" icon="el-icon-plus">添加公屏</el-button>
                    <el-input v-model="name" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' :clearable="true" placeholder="搜索公屏名称"></el-input>
                    <el-select v-model="classify" size="small" @change="searchBtn" :clearable="true" placeholder="请选择分类">
                        <el-option v-for="item in classifyOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="status" size="small" @change="searchBtn" :clearable="true" placeholder="请选择状态">
                        <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <el-table-column prop="sort" label="序号" width="100px"></el-table-column>
                    <el-table-column prop="name" label="公屏名称"></el-table-column>
                    <el-table-column prop="materialCategory.name" label="分类"></el-table-column>
                    <el-table-column prop="background_img" label="公屏缩略图">
                        <template slot-scope="scope">
                            <el-image style="width: 100px; height: 100px" :src="scope.row.background_img" fit="scale-down" :preview-src-list="imgList"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="screen_lable" label="公屏自定义标识"></el-table-column>
                    <el-table-column label="状态">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="添加时间"></el-table-column>
                    <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="editScreen(scope.row)">编辑</el-button>
                            <el-button type="text" size="small" @click="delScreen(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 删除提示 -->
        <point-out ref='dialogTip' dialogTitle="提示" type="material" @close='closeScreen' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">请确认是否删除该公屏，删除后将不可恢复</div>
            </div>
        </point-out>
        <!-- 添加/编辑 -->
        <add-edit ref="addedit" :list="classifyOptions" @affirm="getList"></add-edit>
    </div>
</template>

<script>
let that
import PointOut from '@/components/point_out/point_out.vue'//提示
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import AddEdit from '../components/screen/add_edit'// 添加/编辑

export default {
    components: {PointOut,EmptyData,PagingPage,AddEdit},
    props: {},
    data() {
        return {
            // 名称
            name:'',
            // 分类
            classify:'',
            classifyOptions:[],
            // 状态
            status:'',
            statusOptions:[
                {value:1,label:"开启"},
                {value:2,label:"关闭"},
            ],
            // 
            list: [],
            imgList:[],
            loading: false,
            //
            page: 1,
            limit: 10,
            total: 0,
            // 
            delId:null
        };
    },

    computed: {},

    watch: {},

    created() {
        that = this
        that.getCat()
        that.getList()
    },

    mounted() {},

    methods: {
        // 分类
        getCat(){
            that.$materialApi.getMaterialGetCat().then(res => {
                if (res.code == 1000) {
                    that.classifyOptions = res.result.cat_list
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 数据列表
        getList(){
            that.loading = true
            var params = {
                name: that.name,
                category_id:that.classify,
                status:that.status,
                page:that.page,
                limit:that.limit
            }
            that.$materialApi.getMaterialScreen(params).then(res => {
                that.loading = false
                if (res.code == 1000) {
                    that.list = res.result.data || []
                    that.total = res.result.total || 0
                    that.list.forEach(ele => {
                        that.imgList.push(ele.background_img)
                    })
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 筛选搜索
        searchBtn(){
            that.page = 1
            that.total = 0
            that.getList()
        },
        // 添加
        addScreen(){
            that.$refs.addedit.openDialog()
        },
        // 编辑
        editScreen(info){
            var obj = JSON.parse(JSON.stringify(info))
            that.$refs.addedit.openDialog(obj)
        },
        // 删除
        delScreen(info){
            that.delId = info.id
            that.$refs.dialogTip.openDialogBtn()
        },
        // 确定删除
        closeScreen(){
            if(that.delId!=null){
                var params = {}
                that.$materialApi.delMaterialScreen(params,that.delId).then(res => {
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.delId = null
                        if(this.list.length==1){
                            this.searchBtn()
                        }else{
                            this.getList()
                        }
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
        // 修改状态
        changeSwitch(val, info, index){
            var params = {
                id: info.id,
                status: val
            }
            that.$materialApi.MaterialChangeStatus(params).then(res => {
                if (res.code == 1000) {
                    that.list[index].status = val
                    that.$succMsg(res.message)
                } else {
                    that.list[index].status = val == 1 ? 2 : 1
                    that.$errMsg(res.message)
                }
            })
        },
    }
}
</script>
<style lang='scss' scoped>
.screen_vue{
    width: 100%;
    .screen_main{
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-right: 20px;
                }
                .el-select {
                    margin-right: 20px;
                    width: 160px;
                }
                .el-input {
                    margin-right: 20px;
                    width: 160px;
                }
            }
        }
    }
}
</style>